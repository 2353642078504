import React, { useContext } from "react";
import { Container, Row, Col } from "react-bootstrap";

import GlobalContext from "../../context/GlobalContext";
import { Section, Title, ButtonIcon, LinkContact } from "../../components/Core";
import Availability from "../../components/Availability";

const Hero = () => {
  const gContext = useContext(GlobalContext);

  return (
    <>
      <Section>
        <Container>
          <Row className="text-center justify-content-center">
            <Col lg="10" xl="7">
              {/* <Availability /> */}
              <div className="text-center my-5">
                <Title>Let's talk!</Title>
              </div>
              <div className="text-center">
                <ButtonIcon
                  onClick={(e) => {
                    e.preventDefault();
                    gContext.toggleContact();
                  }}
                >
                  Send a message
                </ButtonIcon>
              </div>
              <div className="d-flex flex-column flex-lg-row justify-content-center" 
                css={`
                margin: 50px;
                `}>
                    <LinkContact
                      href="mailto:ryandaaang@gmail.com"
                      target="_blank"
                      className="mb-2 mb-lg-0"
                    >
                      ryandaaang@gmail.com
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="tel:+1-206-471-4243"
                      // target="_blank"
                    >
                      206.471.4243
                    </LinkContact>
                    <span className="divider mx-2 mx-lg-4 d-none d-lg-block">
                      |
                    </span>
                    <LinkContact
                      href="https://www.linkedin.com/in/ryandaaang/"
                      target="_blank"
                    >
                      LinkedIn.com/in/ryandaaang
                    </LinkContact>
                  </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
